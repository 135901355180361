<template>
    <div class="c_content_container work">
        <!-- 상단배너 START -->
        <section class="c_section c_banner banner_work">
            <div class="c_inner">
                <div class="img_wrapper work">
                    <div class="icon_wrapper">
                        <img src="@/assets/images/banner/icon_work.png" alt="아이콘" class="icon" />
                    </div>
                </div>
            </div>
        </section>
        <!-- 상단배너 END -->

        <!-- 컨텐츠 START -->
        <section class="c_section priceInfo">
            <div class="c_inner">
                <div class="tabMenu_wrap">
                    <div :class="currentValue===0 ? 'on' : ''" @click="() => { onClickValue(0) }">홈페이지 디자인</div>
                    <div :class="currentValue===1 ? 'on' : ''" @click="() => { onClickValue(1) }">홈페이지 퍼블리싱</div>
                    <div :class="currentValue===2 ? 'on' : ''" @click="() => { onClickValue(2) }">상세페이지 디자인</div>
                    <div :class="currentValue===3 ? 'on' : ''" @click="() => { onClickValue(3) }">웹배너 디자인</div>
                </div>
                <PriceContent_HD v-if="currentValue===0"/>
                <PriceContent_HP v-if="currentValue===1"/>
                <PriceContent_LD v-if="currentValue===2"/>
                <PriceContent_WBD v-if="currentValue===3"/>
            </div>
        </section>
        <!-- 컨텐츠 END -->
    </div>
</template>
  
<script>
import PriceContent_HD from '@/components/priceContent/PriceContent_HD.vue'
import PriceContent_HP from '@/components/priceContent/PriceContent_HP.vue'
import PriceContent_LD from '@/components/priceContent/PriceContent_LD.vue'
import PriceContent_WBD from '@/components/priceContent/PriceContent_WBD.vue'

export default {
    components:{
        PriceContent_HD,
        PriceContent_HP,
        PriceContent_LD,
        PriceContent_WBD,
    },
    data(){
        return {
            currentValue: 0,
		};
	},
	methods: {
		onClickValue(val){
			this.currentValue = val;
		}
	}
};
</script>

<style scoped src="@/assets/css/work.css"></style>
  