<template>
    <div class="priceContent">
        <h1 class="section_title">상세페이지 디자인</h1>
        <p class="section_text">
            부담 없이 저렴하게 진행할 수 있는 일반형부터 상품의 판매를 극대화하기 위한 전략형 디자인까지 다양하게 준비되어 있습니다. 
            사용자 유입에서 끝나는 것이 아니라 구매로 전환될 수 있는 상세페이지를 제작해 드리겠습니다.
        </p>
        <div class="priceTable">
            <ul class="leftMenu">
                <li class="bold">구성</li>
                <li>
                    <p>제작 가격</p> <span><img src="@/assets/images/common/icon_info.svg" alt="" /></span>
                </li>
                <li>
                    <p>이미지 보정</p> <span><img src="@/assets/images/common/icon_info.svg" alt="" /></span>
                </li>
                <li>
                    <p>시안 갯수</p> <span><img src="@/assets/images/common/icon_info.svg" alt="" /></span>
                </li>
                <li>
                    <p>수정 횟수</p> <span><img src="@/assets/images/common/icon_info.svg" alt="" /></span>
                </li>
                <li>
                    <p>페이지 수</p>
                </li>
            </ul>
            <Swiper v-bind="SwiperSlideOption" class="mySwiper">
                <swiper-slide>
                    <ul>
                        <li class="title">
                            <p>일반형</p>
                        </li>
                        <li class="bold">
                            <p>50,000원~</p>
                        </li>
                        <li>
                            <span class="check">
                                <img src="@/assets/images/common/icon_table_check.svg"/>
                            </span>
                        </li>
                        <li>
                            <p>1개</p>
                        </li>
                        <li>
                            <p>2회</p>
                        </li>
                        <li>
                            <p>1 페이지</p>
                        </li>
                    </ul>
                </swiper-slide>
                <swiper-slide>
                    <ul>
                        <li class="title">
                            <p>고급형</p>
                        </li>
                        <li class="bold">
                            <p>150,000원~</p>
                        </li>
                        <li>
                            <span class="check">
                                <img src="@/assets/images/common/icon_table_check.svg"/>
                            </span>
                        </li>
                        <li>
                            <p>1개</p>
                        </li>
                        <li>
                            <p>2회</p>
                        </li>
                        <li>
                            <p>1 페이지</p>
                        </li>
                    </ul>
                </swiper-slide>
                <swiper-slide>
                    <ul>
                        <li class="title">
                            <p>전략형</p>
                        </li>
                        <li class="bold">
                            <p>300,000원~</p>
                        </li>
                        <li>
                            <span class="check">
                                <img src="@/assets/images/common/icon_table_check.svg"/>
                            </span>
                        </li>
                        <li>
                            <p>1개</p>
                        </li>
                        <li>
                            <p>2회</p>
                        </li>
                        <li>
                            <p>1페이지</p>
                        </li>
                    </ul>
                </swiper-slide>
            </Swiper>
        </div>
        <div class="priceDetail">
            <ul>
                <li>
                    <span><img src="@/assets/images/common/icon_info.svg" alt="" /></span>
                    <p>기본 5,000px 이하이며, 1,000px 당 10,000원 추가됩니다.</p>
                </li>
                <li>
                    <span><img src="@/assets/images/common/icon_info.svg" alt="" /></span>
                    <p>이미지 기본 보정 제공.</p>
                </li>
                <li>
                    <span><img src="@/assets/images/common/icon_info.svg" alt="" /></span>
                    <p>페이지별로 디자인 타입 제공.</p>
                </li>
                <li>
                    <span><img src="@/assets/images/common/icon_info.svg" alt="" /></span>
                    <p>디자인 콘셉트를 크게 변경하지 않는 선에서 수정 가능(단순 이미지 및 문구 등).</p>
                </li>
            </ul>
        </div>
        <Swiper v-bind="SwiperExampleOption" class="example">
            <swiper-slide>
                <p>일반형</p>
                <div class="image_wrapper">
                    <img src="@/assets/images/work/priceContent_example/basic.jpg" alt=""/>
                </div>
            </swiper-slide>
            <swiper-slide>
                <p>고급형</p>
                <div class="image_wrapper">
                    <img src="@/assets/images/work/priceContent_example/high_01.jpg" alt=""/>
                </div>
            </swiper-slide>
            <swiper-slide>
                <p>고급형</p>
                <div class="image_wrapper">
                    <img src="@/assets/images/work/priceContent_example/high_02.jpg" alt=""/>
                </div>
            </swiper-slide>
            <swiper-slide>
                <p>전략형</p>
                <div class="image_wrapper">
                    <img src="@/assets/images/work/priceContent_example/strategy.jpg" alt=""/>
                </div>
            </swiper-slide>
        </Swiper>
    </div>
</template>

<script>
// Swiper
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.min.css";

import { Pagination } from 'swiper';

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            SwiperSlideOption: {
                spaceBetween: 0,
                pagination: true,
                modules: [Pagination],
                breakpoints: {
                    1024: {
                        slidesPerView: 3,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    479: {
                        slidesPerView: 1,
                    }
                }
            },
            SwiperExampleOption:{
                spaceBetween:40,
                pagination: {
                    clickable:true,
                },
                modules: [Pagination],
                breakpoints: {
                    1024: {
                        slidesPerView: 3,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                }
            }
        };
    },
};
</script>

<style scoped src="@/assets/css/work.css"></style>