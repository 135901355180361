<template>
    <div class="priceContent">
        <h1 class="section_title">홈페이지 퍼블리싱</h1>
        <p class="section_text">
            데버에서는 저가형 홈페이지는 물론 고가의 홈페이지까지 정성을 다해 제작해드리고 있습니다.
            고객님께서 요구하시는 디자인의 퀄리티가 높거나, 콘텐츠의 양이 많을수록 그에 따른 기획과
            분석 및 작업시간을 필요로 하며, 원하시는 홈페이지 기능의 난이도에 따라 견적이 달라질 수 있습니다.
        </p>
        <div class="priceTable">
            <ul class="leftMenu">
                <li class="bold">구성</li>
                <li>
                    <p>레이아웃 제작 가격</p> <span><img src="@/assets/images/common/icon_info.svg" alt="" /></span>
                </li>
                <li>
                    <p>슬라이더/스크롤</p> <span><img src="@/assets/images/common/icon_info.svg" alt="" /></span>
                </li>
                <li>
                    <p>크로스 브라우징</p> <span><img src="@/assets/images/common/icon_info.svg" alt="" /></span>
                </li>
                <li>
                    <p>애니메니션</p> <span><img src="@/assets/images/common/icon_info.svg" alt="" /></span>
                </li>
                <li>
                    <p>페이지당 가격</p> <span><img src="@/assets/images/common/icon_info.svg" alt="" /></span>
                </li>
            </ul>
            <Swiper v-bind="SwiperSlideOption" class="mySwiper">
                <swiper-slide>
                    <ul>
                        <li class="title">
                            <p>메인 페이지</p>
                        </li>
                        <li class="bold">
                            <p>200,000원~</p>
                        </li>
                        <li>
                            <span class="check">
                                <img src="@/assets/images/common/icon_table_check.svg"/>
                            </span>
                        </li>
                        <li>
                            <span class="check">
                                <img src="@/assets/images/common/icon_table_check.svg"/>
                            </span>
                        </li>
                        <li>
                            <span class="check">
                                <img src="@/assets/images/common/icon_table_check.svg"/>
                            </span>
                        </li>
                        <li class="bold">
                            <p>+30,000원~</p>
                        </li>
                    </ul>
                </swiper-slide>
                <swiper-slide>
                    <ul>
                        <li class="title">
                            <p>서브 페이지</p>
                        </li>
                        <li class="bold">
                            <p>100,000원~</p>
                        </li>
                        <li>
                            <span class="check">
                                <img src="@/assets/images/common/icon_table_check.svg"/>
                            </span>
                        </li>
                        <li>
                            <span class="check">
                                <img src="@/assets/images/common/icon_table_check.svg"/>
                            </span>
                        </li>
                        <li>
                            <span class="check">
                                <img src="@/assets/images/common/icon_table_check.svg"/>
                            </span>
                        </li>
                        <li class="bold">
                            <p>+30,000원~</p>
                        </li>
                    </ul>
                </swiper-slide>
                <swiper-slide>
                    <ul>
                        <li class="title">
                            <p>메인 페이지(반응형)</p>
                        </li>
                        <li class="bold">
                            <p>300,000원~</p>
                        </li>
                        <li>
                            <span class="check">
                                <img src="@/assets/images/common/icon_table_check.svg"/>
                            </span>
                        </li>
                        <li>
                            <span class="check">
                                <img src="@/assets/images/common/icon_table_check.svg"/>
                            </span>
                        </li>
                        <li>
                            <span class="check">
                                <img src="@/assets/images/common/icon_table_check.svg"/>
                            </span>
                        </li>
                        <li class="bold">
                            <p>+50,000원~</p>
                        </li>
                    </ul>
                </swiper-slide>
                <swiper-slide>
                    <ul>
                        <li class="title">
                            <p>서브 페이지(반응형)</p>
                        </li>
                        <li class="bold">
                            <p>200,000원~</p>
                        </li>
                        <li>
                            <span class="check">
                                <img src="@/assets/images/common/icon_table_check.svg"/>
                            </span>
                        </li>
                        <li>
                            <span class="check">
                                <img src="@/assets/images/common/icon_table_check.svg"/>
                            </span>
                        </li>
                        <li>
                            <span class="check">
                                <img src="@/assets/images/common/icon_table_check.svg"/>
                            </span>
                        </li>
                        <li class="bold">
                            <p>+50,000원~</p>
                        </li>
                    </ul>
                </swiper-slide>
            </Swiper>
        </div>
        <div class="priceDetail">
            <ul>
                <li>
                    <span><img src="@/assets/images/common/icon_info.svg" alt="" /></span>
                    <p>작업에 맞춰 큰 틀을 구상하는 단계로 필수 포합입니다.</p>
                </li>
                <li>
                    <span><img src="@/assets/images/common/icon_info.svg" alt="" /></span>
                    <p>라이브러리를 이용한 슬라이더 구현.</p>
                </li>
                <li>
                    <span><img src="@/assets/images/common/icon_info.svg" alt="" /></span>
                    <p>각 브라우저의 동일성 맞춤 작업.</p>
                </li>
                <li>
                    <span><img src="@/assets/images/common/icon_info.svg" alt="" /></span>
                    <p>CSS를 이용한 기본 애니메이션.</p>
                </li>
                <li>
                    <span><img src="@/assets/images/common/icon_info.svg" alt="" /></span>
                    <p>표기된 가격은 최소가격이며, 기획과 구성에 따라 가격은 변동됩니다.</p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
// Swiper
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.min.css";

import { Pagination } from 'swiper';

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            SwiperSlideOption: {
                spaceBetween: 0,
                pagination: true,
                modules: [Pagination],
                breakpoints: {
                    1024: {
                        slidesPerView: 4,
                    },
                    960: {
                        slidesPerView: 3,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    479: {
                        slidesPerView: 1,
                    }
                }
            },
        };
    },
};
</script>

<style scoped src="@/assets/css/work.css"></style>