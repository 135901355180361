<template>
    <div class="priceContent">
        <h1 class="section_title">홈페이지 디자인</h1>
        <p class="section_text">
            데버에서는 저가형 홈페이지는 물론 고가의 홈페이지까지 정성을 다해 제작해드리고 있습니다.
            고객님께서 요구하시는 디자인의 퀄리티가 높거나, 콘텐츠의 양이 많을수록 그에 따른 기획과
            분석 및 작업시간을 필요로 하며, 원하시는 홈페이지 기능의 난이도에 따라 견적이 달라질 수 있습니다.
        </p>
        <div class="priceTable">
            <ul class="leftMenu">
                <li class="bold">구성</li>
                <li>
                    <p>제작 가격</p> <span><img src="@/assets/images/common/icon_info.svg" alt="" /></span>
                </li>
                <li>
                    <p>시안 갯수</p> <span><img src="@/assets/images/common/icon_info.svg" alt="" /></span>
                </li>
                <li>
                    <p>수정 횟수</p> <span><img src="@/assets/images/common/icon_info.svg" alt="" /></span>
                </li>
                <li>
                    <p>페이지 수</p>
                </li>
                <li>
                    <p>퍼블리싱 추가</p>
                </li>
            </ul>
            <Swiper v-bind="SwiperSlideOption" class="mySwiper">
                <swiper-slide>
                    <ul>
                        <li class="title">
                            <p>메인 페이지</p>
                        </li>
                        <li class="bold">
                            <p>200,000원~</p>
                        </li>
                        <li>
                            <p>1회</p>
                        </li>
                        <li>
                            <p>2회</p>
                        </li>
                        <li>
                            <p>1 페이지</p>
                        </li>
                        <li class="bold">
                            <p>+150,000원~</p>
                        </li>
                    </ul>
                </swiper-slide>
                <swiper-slide>
                    <ul>
                        <li class="title">
                            <p>서브 페이지</p>
                        </li>
                        <li class="bold">
                            <p>100,000원~</p>
                        </li>
                        <li>
                            <p>1회</p>
                        </li>
                        <li>
                            <p>2회</p>
                        </li>
                        <li>
                            <p>1 페이지</p>
                        </li>
                        <li class="bold">
                            <p>+50,000원~</p>
                        </li>
                    </ul>
                </swiper-slide>
                <swiper-slide>
                    <ul>
                        <li class="title">
                            <p>원 페이지</p>
                        </li>
                        <li class="bold">
                            <p>500,000원~</p>
                        </li>
                        <li>
                            <p>1회</p>
                        </li>
                        <li>
                            <p>2회</p>
                        </li>
                        <li>
                            <p>1 페이지</p>
                        </li>
                        <li class="bold">
                            <p>기본 제공</p>
                        </li>
                    </ul>
                </swiper-slide>
            </Swiper>
        </div>
        <div class="priceDetail">
            <ul>
                <li>
                    <span><img src="@/assets/images/common/icon_info.svg" alt="" /></span>
                    <p>표기된 가격은 최소가격이며, 기획과 구성에 따라 가격은 변동됩니다.</p>
                </li>
                <li>
                    <span><img src="@/assets/images/common/icon_info.svg" alt="" /></span>
                    <p>페이지별로 디자인 타입 제공.</p>
                </li>
                <li>
                    <span><img src="@/assets/images/common/icon_info.svg" alt="" /></span>
                    <p>디자인 콘셉트를 크게 변경하지 않는 선에서 수정 가능(단순 이미지 및 문구 등).</p>
                </li>
            </ul>
        </div>
        <Swiper v-bind="SwiperExampleOption" class="example">
            <swiper-slide>
                <p>메인 페이지</p>
                <div class="image_wrapper">
                    <img src="@/assets/images/work/priceContent_example/main_01.jpg" alt=""/>
                </div>
            </swiper-slide>
            <swiper-slide>
                <p>서브 페이지</p>
                <div class="image_wrapper">
                    <img src="@/assets/images/work/priceContent_example/sub_01.jpg" alt=""/>
                </div>
            </swiper-slide>
            <swiper-slide>
                <p>메인 페이지</p>
                <div class="image_wrapper">
                    <img src="@/assets/images/work/priceContent_example/main_02.jpg" alt=""/>
                </div>
            </swiper-slide>
            <swiper-slide>
                <p>서브 페이지</p>
                <div class="image_wrapper">
                    <img src="@/assets/images/work/priceContent_example/sub_02.jpg" alt=""/>
                </div>
            </swiper-slide>
            <swiper-slide>
                <p>원 페이지</p>
                <div class="image_wrapper">
                    <img src="@/assets/images/work/priceContent_example/one.jpg" alt=""/>
                </div>
            </swiper-slide>
        </Swiper>
    </div>
</template>

<script>
// Swiper
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.min.css";

import { Pagination } from 'swiper';

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            SwiperSlideOption: {
                spaceBetween: 0,
                pagination: true,
                modules: [Pagination],
                breakpoints: {
                    1024: {
                        slidesPerView: 3,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    479: {
                        slidesPerView: 1,
                    }
                }
            },
            SwiperExampleOption:{
                spaceBetween:40,
                pagination: {
                    clickable:true,
                },
                modules: [Pagination],
                breakpoints: {
                    1024: {
                        slidesPerView: 3,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    479: {
                        slidesPerView: 2,
                    },
                }
            }
        };
    },
};
</script>

<style scoped src="@/assets/css/work.css"></style>