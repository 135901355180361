<template>
    <div class="priceContent">
        <h1 class="section_title">웹배너 디자인</h1>
        <p class="section_text">
            홈페이지 내에서 브랜드의 아이덴티티 형성에 도움을 주는 콘셉트 배너. 
            클릭 전환율을 높여 사용자에게 정보를 효과적으로 전달하는 상품 및 이벤트 배너들까지! 
            데버에서 효율적으로 진행해 드립니다.
        </p>
        <div class="priceTable">
            <ul class="leftMenu">
                <li class="bold">구성</li>
                <li>
                    <p>제작 가격</p> <span><img src="@/assets/images/common/icon_info.svg" alt="" /></span>
                </li>
                <li>
                    <p>이미지 보정</p> <span><img src="@/assets/images/common/icon_info.svg" alt="" /></span>
                </li>
                <li>
                    <p>시안 갯수</p> <span><img src="@/assets/images/common/icon_info.svg" alt="" /></span>
                </li>
                <li>
                    <p>수정 횟수</p> <span><img src="@/assets/images/common/icon_info.svg" alt="" /></span>
                </li>
                <li>
                    <p>이미지 수</p>
                </li>
            </ul>
            <Swiper v-bind="SwiperSlideOption" class="mySwiper">
                <swiper-slide>
                    <ul>
                        <li class="title">
                            <p>500 x 500 이하 배너</p>
                        </li>
                        <li class="bold">
                            <p>50,000원~</p>
                        </li>
                        <li>
                            <span class="check">
                                <img src="@/assets/images/common/icon_table_check.svg"/>
                            </span>
                        </li>
                        <li>
                            <p>1개</p>
                        </li>
                        <li>
                            <p>2회</p>
                        </li>
                        <li>
                            <p>1 페이지</p>
                        </li>
                    </ul>
                </swiper-slide>
                <swiper-slide>
                    <ul>
                        <li class="title">
                            <p>500 x 500 이상 배너</p>
                        </li>
                        <li class="bold">
                            <p>70,000원~</p>
                        </li>
                        <li>
                            <span class="check">
                                <img src="@/assets/images/common/icon_table_check.svg"/>
                            </span>
                        </li>
                        <li>
                            <p>1개</p>
                        </li>
                        <li>
                            <p>2회</p>
                        </li>
                        <li>
                            <p>1 페이지</p>
                        </li>
                    </ul>
                </swiper-slide>
            </Swiper>
        </div>
        <div class="priceDetail">
            <ul>
                <li>
                    <span><img src="@/assets/images/common/icon_info.svg" alt="" /></span>
                    <p>배경 위에 텍스트와 상품이 들어가는 형식.</p>
                </li>
                <li>
                    <span><img src="@/assets/images/common/icon_info.svg" alt="" /></span>
                    <p>이미지 기본 보정 제공.</p>
                </li>
                <li>
                    <span><img src="@/assets/images/common/icon_info.svg" alt="" /></span>
                    <p>페이지별로 디자인 타입 제공.</p>
                </li>
                <li>
                    <span><img src="@/assets/images/common/icon_info.svg" alt="" /></span>
                    <p>디자인 콘셉트를 크게 변경하지 않는 선에서 수정 가능(단순 이미지 및 문구 등).</p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
// Swiper
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.min.css";

import { Pagination } from 'swiper';

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            SwiperSlideOption: {
                spaceBetween: 0,
                pagination: true,
                modules: [Pagination],
                breakpoints: {
                    768: {
                        slidesPerView: 2,
                    },
                    479: {
                        slidesPerView: 1,
                    }
                }
            },
        };
    },
};
</script>

<style scoped src="@/assets/css/work.css"></style>