<template>
	<div class="c_content_container work">
		<!-- 상단배너 START -->
		<section class="c_section c_banner banner_work">
			<div class="c_inner">
				<div class="img_wrapper work">
					<div class="icon_wrapper">
						<img src="@/assets/images/banner/icon_work.png" alt="아이콘" class="icon" />
					</div>
				</div>
			</div>
			<div class="c_inner">
				<div class="page_text">
					<h2 v-textMotion class="text_large">
						<span class="textMotion_target">
							<span class="textMotion_target"><span class="text_deco"></span>{{ $t("Work Bf 01") }}</span>
						</span><br />
						<span class="textMotion_target">
							<span class="textMotion_target"><span class="text_deco"></span>{{ $t("Work Bf 02") }}</span>
						</span><br />
						<span class="textMotion_target">
							<span class="textMotion_target"><span class="text_deco"></span><span class="text_deco">{{
								$t("Work Bf 03") }}</span>
								{{ $t("Work Bf 04") }}</span>
						</span>
					</h2>
				</div>
			</div>
		</section>
		<!-- 상단배너 END -->

		<!-- 컨텐츠 START -->
		<section class="c_section work_content">
			<div class="c_inner">
				<span class="line"></span>
				<ul>
					<!--웹에이전시-->
					<li>
						<div v-imageMotion class="img_wrapper">
							<div class="img_holder"></div>
						</div>
						<div class="text_wrapper">
							<div class="icon_wrapper">
								<img src="@/assets/images/work/icon_work_01.png" alt="아이콘" />
							</div>
							<div v-fadein:reverse class="text">
								<h2 v-textMotion class="text_large">
									<span class="textMotion_target">
										<span class="text_deco">{{ $t("Web Agency") }}</span>
									</span>
								</h2>
								<p>{{ $t("Work Web Agency") }}</p>
							</div>
							<router-link to="/reference">{{ $t("Watch") }}</router-link>
						</div>
					</li>
					<!--쇼핑몰 운영-->
					<li>
						<div v-imageMotion class="img_wrapper">
							<div class="img_holder"></div>
						</div>
						<div class="text_wrapper">
							<div class="icon_wrapper">
								<img src="@/assets/images/work/icon_work_02.png" alt="아이콘" />
							</div>
							<div v-fadein:reverse class="text">
								<h2 v-textMotion class="text_large">
									<span class="textMotion_target">
										<span class="text_deco">{{ $t("Run Online Store") }}</span>
									</span>
								</h2>
								<p>{{ $t("Run Online Store Txt") }}</p>
							</div>
							<a href="javascript:void(0);">{{ $t("Preparing") }}</a>
						</div>
					</li>
					<!--이커머스-->
					<li>
						<div v-imageMotion class="img_wrapper">
							<div class="img_holder"></div>
						</div>
						<div class="text_wrapper">
							<div class="icon_wrapper">
								<img src="@/assets/images/work/icon_work_03.png" alt="아이콘" />
							</div>
							<div v-fadein:reverse class="text">
								<h2 v-textMotion class="text_large">
									<span class="textMotion_target">
										<span class="text_deco">{{ $t("Ecommerce") }}</span>
									</span>
								</h2>
								<p>{{ $t("Ecommerce Txt") }}</p>
							</div>
							<a target="_blank" href="https://sonagimall.com/">{{ $t("Visit") }}</a>
						</div>
					</li>
					<!--창업지원 서비스-->
					<li>
						<div v-imageMotion class="img_wrapper">
							<div class="img_holder"></div>
						</div>
						<div class="text_wrapper">
							<div class="icon_wrapper">
								<img src="@/assets/images/work/icon_work_04.png" alt="아이콘" />
							</div>
							<div v-fadein:reverse class="text">
								<h2 v-textMotion class="text_large">
									<span class="textMotion_target">
										<span class="text_deco">{{ $t("Startup Support Sv") }}</span>
									</span>
								</h2>
								<p>{{ $t("Startup Support Sv Txt") }}</p>
							</div>
							<a target="_blank" href="https://introduce.ownerapp.co.kr/">{{ $t("Visit") }}</a>
						</div>
					</li>
					<!--편집 디자인-->
					<li>
						<div v-imageMotion class="img_wrapper">
							<div class="img_holder"></div>
						</div>
						<div class="text_wrapper">
							<div class="icon_wrapper">
								<img src="@/assets/images/work/icon_work_05.png" alt="아이콘" />
							</div>
							<div class="text">
								<h2 v-textMotion class="text_large">
									<span class="textMotion_target">
										<span class="text_deco">{{ $t("Editorial Design") }}</span>
									</span>
								</h2>
								<p>{{ $t("Editorial Design Txt") }}</p>
							</div>
							<a href="javascript:void(0);">{{ $t("Preparing") }}</a>
						</div>
					</li>
					<!--마케팅-->
					<li>
						<div v-imageMotion class="img_wrapper">
							<div class="img_holder"></div>
						</div>
						<div class="text_wrapper">
							<div class="icon_wrapper">
								<img src="@/assets/images/work/icon_work_06.png" alt="아이콘" />
							</div>
							<div v-fadein:reverse class="text">
								<h2 v-textMotion class="text_large">
									<span class="textMotion_target">
										<span class="text_deco">{{ $t("Marketing") }}</span>
									</span>
								</h2>
								<p>{{ $t("Marketing Txt") }}</p>
							</div>
							<a href="javascript:volid(0);">{{ $t("Preparing") }}</a>
						</div>
					</li>
					<!--인쇄물 제작, 생산-->
					<li>
						<div v-imageMotion class="img_wrapper">
							<div class="img_holder"></div>
						</div>
						<div class="text_wrapper">
							<div class="icon_wrapper">
								<img src="@/assets/images/work/icon_work_07.png" alt="아이콘" />
							</div>
							<div v-fadein:reverse class="text">
								<h2 v-textMotion class="text_large">
									<span class="textMotion_target">
										<span class="text_deco">{{ $t("Production Pt Mt") }}</span>
									</span>
								</h2>
								<p>{{ $t("Production Pt Mt Txt") }}</p>
							</div>
							<a target="_blank" href="https://papier.co.kr/">{{ $t("Visit") }}</a>
						</div>
					</li>
				</ul>
			</div>
		</section>
		<!-- 컨텐츠 END -->
	</div>
</template>

<script>
export default {};
</script>

<style scoped src="@/assets/css/work.css"></style>
